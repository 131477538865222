<template>
  <div class="shareButton">
    <div class="shareButton1" v-if="shareType == 0 || shareType == 2" v-loading="shareStatus" @click="shareChange(1)">
      <img src="@/assets/image/icon/icon-share.png" alt="">
      分享螢幕畫面
    </div>
    <div class="shareButton2" v-else v-loading="shareStatus" @click="shareChange(2)">
      <img src="@/assets/image/icon/icon-pause.png" alt="">
      停止分享螢幕
    </div>
  </div>
</template>
 
<script>
import store from '@/store'
import { mapGetters } from "vuex"
export default {
  name: 'ShareButton',
  computed: {
    ...mapGetters(['shareType', 'shareStatus'])
  },
  data() {
    return {
    }
  },
  methods: {
    // 分享熒幕
    shareChange(type) {
      if (type == 1) {
        store.dispatch('agora/openScreenShare')
      } else {
        store.dispatch('agora/stopScreenShare')
      }
    },
  }
}
</script>

<style lang="less" scoped>
.shareButton {
  user-select: none;
  cursor: pointer;
  position: absolute;
  top: 14px;
  right: 14px;
  .shareButton1 {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 136px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #0C4FA2;
    border-radius: 8px;
    font-family: "Microsoft JhengHei","微軟正黑體";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 32px;
    color: #0C4FA2;
    img {
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }
  }
  .shareButton2 {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 136px;
    height: 40px;
    background: #0C4FA2;
    border: 1px solid #0C4FA2;
    border-radius: 8px;
    font-family: "Microsoft JhengHei","微軟正黑體";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 32px;
    color: #FFFFFF;
    img {
      margin-right: 8px;
      width: 18px;
      height: 18px;
    }
  }
}
</style>