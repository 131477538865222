<template>
  <transition
    enter-active-class="animated fadeIn"
    leave-active-class="animated fadeOut"
  >
    <div class="ProblemReturns" v-if="problemStatus != 0">
      <template v-if="problemStatus == 1">
        <img class="ProblemReturns-img" src="@/assets/image/icon/icon-returns.png" alt="">
        <h1 class="ProblemReturns-h1">取消問題回報</h1>
      </template>
      <template v-if="problemStatus == 2">
        <img class="ProblemReturns-img" src="@/assets/image/icon/icon-returnss.png" alt="">
        <h1 class="ProblemReturns-h1" v-if="problemResultsStatus == 1">已完成處理</h1>
        <h1 class="ProblemReturns-h1" v-if="problemResultsStatus == 2">無法處理問題但已註記問題狀況</h1>
        <h1 class="ProblemReturns-h1" v-if="problemResultsStatus == 3">無法成功聯繫學生</h1>
      </template>
      <div class="ProblemReturns-p">
        <p>時間恢復計算</p>
        <p>請繼續作答</p>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex"
export default { 
  name: 'ProblemReturns',
  computed: {
    ...mapGetters(['problemStatus', 'problemResultsStatus'])
  },
  data() {
    return {
      // 關閉5秒倒計時 實際時間+1
      countDown: 6,
    }
  },
  watch: {
    // 狀態碼變更的時候 調用倒計時
    problemStatus(val) {
      if (val != 0) {
        this.countDown = 6
        this.countTime()
      }
    }
  },
  methods: {
    // 倒計時
    countTime() {
      if (this.countDown > 0) {
        this.countDown -= 1
        setTimeout(this.countTime, 1000)
      } else {
        this.$store.commit('problem/SET_PROBLEMSTATUS', 0)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ProblemReturns {
  padding: 100px 17px 0;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 120px;
  height: calc(100% - 100px);
  background: #2B69F5;
  border-radius: 0 8px 8px 0;
  text-align: center;
  .ProblemReturns-img {
    width: 40px;
    height: 40px;
  }
  .ProblemReturns-h1 {
    margin: 30px 0;
    font-family: "Microsoft JhengHei","微軟正黑體";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #FFFFFF;
  }
  .ProblemReturns-p {
    font-family: "Microsoft JhengHei","微軟正黑體";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    color: #FFFFFF;
  }
}
</style>